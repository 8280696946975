<template>
  <div class="product-grid bg-white">
    <table class="table mb-0" v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="order-date">Дата заказа/заявки</th>
          <th class="order-points">Сумма начислений в баллах</th>
          <th class="order-comment">Примечание</th>
        </tr>
      </thead>
      <tbody v-if="isCurrentUserDealer">
        <template v-for="(item, index) in items">
          <tr
            :key="index"
            :class="{ 'collapse history-collapse fade': index > 17 }"
          >
            <td class="order-date">{{ item.date }}</td>
            <td class="order-points numbers">{{ item.points }}</td>
            <td class="order-comment" :class="getCommentClass(item)">
              {{ item.comment }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div
      class="accordion"
      id="accordion"
      v-if="isCurrentUserStructuralSubdivisionUser"
    >
      <div class="accordion-item" v-for="item in items" :key="item.id">
        <div class="accordion-header" :id="'heading' + item.id">
          <div class="table-responsive">
            <table
              class="table prod-table w-100 m-0 collapsed accordion-toggle"
              role="button"
              data-toggle="collapse"
              :data-target="'#collapse' + item.id"
              aria-expanded="true"
              :aria-controls="'collapse' + item.id"
            >
              <tbody>
                <tr>
                  <td style="width: 33.3333%">{{ item.date }}</td>
                  <td style="width: 33.3333%" class="numbers">
                    {{ item.points }}
                  </td>
                  <td style="width: 33.3333%" :class="getCommentClass(item)">
                    {{ item.comment }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          :id="'collapse' + item.id"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + item.id"
          data-parent="#accordion"
        >
          <div class="accordion-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Наименование</th>
                    <th>Количество</th>
                    <th>Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in item.products" :key="product.id">
                    <td>{{ product.name }}</td>
                    <td>{{ product.quantity }}</td>
                    <td>
                      {{
                        (product.price * product.quantity) | fractionSizeSpace
                      }}
                      {{ makeDeclension(product.price * product.quantity) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-4" v-if="items && items.length > 17">
      <button
        @click="isActive = !isActive"
        :class="{ 'd-none': isActive }"
        class="btn btn-link btn-default"
        type="button"
        data-toggle="collapse"
        data-target=".history-collapse"
        aria-expanded="false"
      >
        Показать всю историю
      </button>
    </div>
    <div class="product-grid" v-if="!items || !(items.length > 0)">
      <div class="d-flex justify-content-md-center">
        <div class="ss-header">
          <span class="title-desc"><strong>Тут пока ничего нет.</strong></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/lib/axios";
import { DeclensionMaker } from "@/utilities/declension-maker.ts";

export default {
  name: "History",
  data: () => ({
    items: [],
    isActive: false,
  }),
  methods: {
    async fetchData() {
      await get("profile/history").then((response) => {
        this.items = response.data.data;
      });
    },
    getCommentClass(item) {
      if (item.product_order_number || item.visit_request_order_number) {
        return "order";
      }
    },
    makeDeclension(price) {
      return DeclensionMaker.getDeclension(price, "балл", "балла", "баллов");
    },
  },
  mounted() {
    this.fetchData();
  },
  metaInfo() {
    return {
      title: `Профиль - Баланс - ЛУКМАРКЕТ`,
    };
  },
};
</script>

<style scoped lang="scss">
.ss-header {
  padding: 20px 0;
  border-bottom: 2px solid #f5f5f5;
}

.table {
  font-size: 16px;
  /*min-width: 500px;*/
}

@media screen and (max-width: 400px) {
  .table {
    font-size: 12px;
  }
}

.order {
  font-size: 14px;
  font-weight: 700;
  color: #2525fe;
}

.accordion {
  &-header {
    position: relative;

    .table {
      //white-space: nowrap;
    }
  }

  &-toggle {
    &:after {
      content: "";
      position: absolute;
      right: 15px;
      top: 15px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(225deg);
      vertical-align: middle;
      color: #555;
    }
  }
}

.accordion-body {
  border-top: 2px solid #4a4a4a;
  border-bottom: 2px solid #4a4a4a;
}

.collapsed:after {
  transform: rotate(45deg);
}

.order-date {
  width: 20%;
}

.order-points {
  width: 20%;
}

.order-comment {
  width: 60%;
}
</style>
